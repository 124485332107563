export type TExtraEventsReducer = {
  loading: boolean;
  error: string | null;
  error_code: number;
  error_message: string;
  event_id: number | null;
  eventsToChoose: TExtraEvent[]; //Список событий для выбора, включая невыбранные
  calendar: TExtraEvent[]; //Список событий, которые будут проводится, сохраненный в базе
};

export type TExtraEvent = {
  isAdded?: boolean;
  event_id: number | null;
  event_name?: string;
  event_date: string;
  hour: number;
  minute: number;
};

export type TAddExtraEventArgs = {
  event_id: number | null;
  event_date: string;
  hour: number;
  minute: number;
};

export type TAddExtraEventResponse = {
  error_code: number;
  error_message: string;
  result: {};
};

export type TDeleteExtraEventArgs = {
  event_id: number | null;
  event_date: string;
};

export type TDeleteExtraEventResponse = {
  error_code: number;
  error_message: string;
  result: {};
};

export type TUpdateExtraEventArgs = {
  event_id: number | null;
  event_date: string;
  hour: number;
  minute: number;
};

export type TUpdateExtraEventResponse = {
  error_code: number;
  error_message: string;
  result: {};
};

export type TgetCalendarArgs = {
  event_id: number;
  year: number;
};

export type TgetCalendarResponse = {
  error_code: number;
  error_message: string;
  result: {
    calendar: TExtraEvent[];
  };
};

export type TSetExtraEventPayload = {
  event: TExtraEvent;
  index: number;
};

export const InitialExtraEvent = {
  isAdded: false,
  event_id: null,
  event_date: "",
  hour: 9,
  minute: 0,
};
