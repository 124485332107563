import { NameSpace } from "../root-reducer";
import { State } from "../../types/state";
import { ResultsReducer, TDoubleResultAthlete } from "./types";
import { createSelector } from "@reduxjs/toolkit";
import { ExtLink, REACT_APP_WEBSITE_URL } from "../../const";
import { getUserParks } from "../parks/selectors";
import { allowedResultsCount } from "../../utils/doubleResults";

export const getResults = (state: State) => state[NameSpace.results].results;
export const getResultsEventStatus = (state: State) =>
  state[NameSpace.results].status?.id;

// Возможно, надо поднимать флаг isDataLoaded, но тогда его надо поднимать когда ручной ввод тоже
export const getResultsLoaded = (state: State) =>
  state[NameSpace.results].results?.length > 0 ? true : false;
export const getResultsLoading = (state: State) =>
  state[NameSpace.results].loading;
export const getIsDataLoaded = (state: State) =>
  state[NameSpace.results].isDataLoaded;
export const getResultsEventDate = (state: State): string =>
  state[NameSpace.results].event_date;
export const getResultsEventId = (state: State): number | null =>
  state[NameSpace.results].event_id;
export const getEventData = (state: State): ResultsReducer =>
  state[NameSpace.results];
export const getResultsNavigate = (state: State): boolean =>
  state[NameSpace.results].resultsNavigate;

// historyPosition равен порядковому номеру записи в истории. 1 - одна текущий стэйт. читать нечего.
export const isUndoHistory = (state: State): boolean =>
  state[NameSpace.results].historyPosition > 1;
// redo есть если historyPosition показывает на меньше чем последний (длина массива) элемент.
export const isRedoHistory = (state: State): boolean =>
  state[NameSpace.results].historyPosition <
  state[NameSpace.results].resultsHistory.length;
export const getHistoryLength = (state: State) =>
  state[NameSpace.results].resultsHistory.length;
export const getHistoryPosition = (state: State) =>
  state[NameSpace.results].historyPosition;
export const getResultsHistory = (state: State) =>
  state[NameSpace.results].resultsHistory;
export const getShowLoadVolunteersModal = (state: State) =>
  state[NameSpace.results].showLoadVolunteersModal;
export const getShowWebsiteLink = (state: State) =>
  state[NameSpace.results].showWebsiteLink;

export const getEditRow = (state: State): number | null =>
  state[NameSpace.results].editRow;
export const getCurrentRow = (state: State): number | null =>
  state[NameSpace.results].currentRow;
export const getEventDataLength = (state: State) =>
  state[NameSpace.results].results?.length;

// Для изменения навигации в зависимости от стадии.
export const getIsResultsActive = (state: State) =>
  state[NameSpace.results].resultsActive;

export const getDoubleResults = (state: State) =>
  state[NameSpace.results].doubleResults;

export const getIsUniqueID = createSelector(
  [getResults, (_, id: number | null) => id],
  (results, id): boolean => {
    if (id) {
      return results.filter((result) => result.athlete_id === id).length === 1;
    } else return false;
  }
);

export const getIsResultCountExceeded = createSelector(
  [getDoubleResults, getResultsEventDate, (_, id: number | null) => id],
  (doubleResults, date, id): boolean => {
    function exceedsMaxAllowedResults(count: number) {
      return count >= allowedResultsCount(date);
    }

    function athleteHaveId(expectedId: number | null) {
      return function (athlete: TDoubleResultAthlete) {
        return athlete.athlete_id === expectedId;
      };
    }

    function athleteHaveDoubleResults() {
      if (!id || doubleResults.length === 0) return false;
      return doubleResults.findIndex(athleteHaveId(id)) !== -1;
    }

    if (athleteHaveDoubleResults()) {
      let athleteIndex = doubleResults.findIndex(athleteHaveId(id));
      let athleteRunCount = doubleResults[athleteIndex].run_count;
      if (exceedsMaxAllowedResults(athleteRunCount)) return true;
    }

    return false;
  }
);

export const getResultsLink = createSelector(
  getUserParks,
  getResultsEventDate,
  getResultsEventId,
  (parks, date, id): string => {
    if (parks && date && id) {
      let parkById = parks.find((park) => park.id === id);
      if (parkById && parkById.url)
        return `${REACT_APP_WEBSITE_URL}${ExtLink.Results}/?eventName=${parkById.url}&date=${date}`;
    }
    return "";
  }
);
