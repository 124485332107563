import { InitialExtraEvent, TExtraEvent } from "../store/extraEvents/types";
import { isSameOrFutureDate } from "../utils";
import { twoDigitString } from "./addTime";

export const extraEventsList = (
  dates: string[],
  calendar: TExtraEvent[],
  eventId: number
) => {
  // Берет массив дат и возвращает массив мероприятий имеющихся в calendar или пустых
  return dates.map((date) => {
    let eventInCalendar = calendar.find(eventDateIs(date));
    return eventInCalendar ? eventFromCalendar(eventInCalendar) : emptyEvent();

    function eventDateIs(dateToCheck: string) {
      return function (event: TExtraEvent) {
        return event.event_date === dateToCheck;
      };
    }
    function eventFromCalendar(event: TExtraEvent) {
      return { ...event, isAdded: true };
    }
    function emptyEvent() {
      return {
        ...InitialExtraEvent,
        event_date: date,
        event_id: eventId,
        isAdded: false,
      };
    }
  });
};

export function timeStringFromEvent(event: TExtraEvent) {
  return `${twoDigitString(event.hour)}:${twoDigitString(event.minute)}`;
}

export const isEventActive = (event: TExtraEvent): boolean =>
  isSameOrFutureDate(event.event_date);

export const isAnyActiveExtraEvent = (eventDates: string[]): boolean =>
  eventDates.findIndex(isSameOrFutureDate) !== -1;
