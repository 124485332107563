import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  AppRoute,
  AuthorizationStatus,
  DATE_FORMAT,
  TEST_MODE,
} from "../../const";
import MainScreen from "../../pages/MainScreen/MainScreen";
import ResultsScreen from "../../pages/ResultsScreen/ResultsScreen";
import "antd/dist/antd.min.css";
import Volunteers from "../../pages/VolunteersScreen/Volunteers";
import "./app.scss";
import { getAuthorizationStatus, getUserId } from "../../store/user/selectors";
import { useEffect } from "react";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { loadAuthParksAction } from "../../store/parks/thunk";
import { dropToken, getToken } from "../../services/token";
import { setTokenFromStorage, userLogout } from "../../store/user/slice";
import decode from "jwt-decode";
import { useAppSelector } from "../../customHooks/useAppSelector";
import SignInScreen from "../../pages/SignInScreen/SignInScreen";
import NotFoundScreen from "../../pages/NotFoundScreen/NotFoundScreen";
import ResultsFilesUpload from "../../pages/ResultsFilesUpload/ResultsFilesUpload";
import Header from "../Header/Header";
import { loadRolesAction } from "../../store/volunteers/thunk";
import { setResultsEventDate } from "../../store/results/slice";
import moment from "moment";
import { getNextSaturday, getSaturday } from "../../utils";
import { setCancelEventDate } from "../../store/cancelEvent/slice";
import CancelEvent from "../../pages/CancelEvent/CancelEvent";
import Incident from "../../pages/Incident/Incident";
import { clearIncident } from "../../store/incident/slice";
import { clearSearch } from "../../store/search/slice";
import { setInitialPositions } from "../../store/volunteers/slice";
import { getIdList } from "../../services/volunteersIdList";
import ExtraEvents from "../../pages/ExtraEvents/ExtraEvents";
import PasswordForgot from "../../pages/PasswordForgot/PasswordForgot";
import { fetchUserNameById } from "../../store/user/thunk";

function App() {
  const dispatch = useAppDispatch();
  const authorizationStatus = useAppSelector(getAuthorizationStatus);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserId = useAppSelector(getUserId);

  useEffect(() => {
    console.log("TEST_MODE: ", TEST_MODE);
  }, []);

  useEffect(() => {
    dispatch(clearSearch());
    dispatch(clearIncident());
    if (authorizationStatus === AuthorizationStatus.Auth) {
      dispatch(loadAuthParksAction());
      dispatch(loadRolesAction());
      dispatch(setResultsEventDate(moment(getSaturday()).format(DATE_FORMAT)));
      dispatch(
        setCancelEventDate(moment(getNextSaturday()).format(DATE_FORMAT))
      );
      dispatch(setInitialPositions(getIdList()));
      if (currentUserId) dispatch(fetchUserNameById(currentUserId)); //Изменить работу с юзером. не надо передавать ID
    }
  }, [authorizationStatus, currentUserId, dispatch]);

  useEffect(() => {
    if (location.pathname === AppRoute.RemindPassword) return;
    if (authorizationStatus !== AuthorizationStatus.Auth) {
      const token = getToken();
      if (token) {
        try {
          const decodedToken: any = decode(token);
          // console.log(decodedToken?.exp);
          if (decodedToken.exp < Date.now() / 1000) {
            // console.log("Токен просрочен");
            dispatch(userLogout());
            navigate(AppRoute.Login);
          } else {
            dispatch(setTokenFromStorage(token));
            // console.log("Не авторизован, положил токен");
          }
        } catch (err) {
          // console.log("Дропаю токен, он поврежден");
          dropToken();
        }
      } else {
        navigate(AppRoute.Login);
      }
    }
  }, [authorizationStatus, dispatch, navigate, location.pathname]);

  return (
    <>
      <Header />
      <div className="main-container">
        <Routes>
          <Route path={AppRoute.RemindPassword} element={<PasswordForgot />} />
          <Route path={AppRoute.Home} element={<MainScreen />} />
          <Route path={AppRoute.CancelEvent} element={<CancelEvent />} />
          <Route path={AppRoute.Incident} element={<Incident />} />
          <Route path={AppRoute.Login} element={<SignInScreen />} />
          <Route path={AppRoute.Results} element={<ResultsScreen />} />
          <Route path={AppRoute.ExtraEvents} element={<ExtraEvents />} />
          <Route
            path={AppRoute.ResultsFilesUpload}
            element={<ResultsFilesUpload />}
          />
          <Route path={AppRoute.Volunteers} element={<Volunteers />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
