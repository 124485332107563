import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import {
  APIRoute,
  EXTRA_EVENTS_CALENDAR_YEAR,
  EXTRA_EVENTS_DATES,
} from "../../const";
import { extraEventsList } from "../../utils/extraEvents";
import { api } from "../root-reducer";
import { setExtraEvents } from "./slice";
import {
  TAddExtraEventArgs,
  TDeleteExtraEventArgs,
  TgetCalendarArgs,
  TUpdateExtraEventArgs,
} from "./types";

export const AddExtraEventAction = createAsyncThunk(
  "extraEvents/Add",
  async (args: TAddExtraEventArgs, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(APIRoute.ExtraEventsAdd, args);
      if (response.data.errorMessage) {
        notification.error({ message: response.data.errorMessage });
      } else if (response.status === 200) {
        notification.success({
          message: "Дополнительное мероприятие зарегистрировано",
        });
      }
      if (args.event_id)
        dispatch(
          getCalendarAction({
            event_id: args.event_id,
            year: EXTRA_EVENTS_CALENDAR_YEAR,
          })
        );
      return response.data;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({ message: error.response.data.errorMessage });
      } else {
        notification.error({ message: "Произошла ошибка запроса" });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteExtraEventAction = createAsyncThunk(
  "extraEvents/Delete",
  async (args: TDeleteExtraEventArgs, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(APIRoute.ExtraEventDelete, args);
      if (response.data.errorMessage) {
        notification.error({ message: response.data.errorMessage });
      } else if (response.status === 200) {
        notification.success({
          message: "Дополнительное мероприятие удалено",
        });
      }
      if (args.event_id)
        dispatch(
          getCalendarAction({
            event_id: args.event_id,
            year: EXTRA_EVENTS_CALENDAR_YEAR,
          })
        );
      return response.data;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({ message: error.response.data.errorMessage });
      } else {
        notification.error({ message: "Произошла ошибка запроса" });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateExtraEventAction = createAsyncThunk(
  "extraEvents/Update",
  async (args: TUpdateExtraEventArgs, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(APIRoute.ExtraEventUpdate, args);
      if (response.data.errorMessage) {
        notification.error({ message: response.data.errorMessage });
      } else if (response.status === 200) {
        notification.success({
          message: "Данные обновлены",
        });
      }
      if (args.event_id)
        dispatch(
          getCalendarAction({
            event_id: args.event_id,
            year: EXTRA_EVENTS_CALENDAR_YEAR,
          })
        );
      return response.data;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({ message: error.response.data.errorMessage });
      } else {
        notification.error({ message: "Произошла ошибка запроса" });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCalendarAction = createAsyncThunk(
  "extraEvents/getCalendar",
  async (args: TgetCalendarArgs, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(APIRoute.ExtraEventsGetCalendar, args);
      if (response.data.errorMessage) {
        notification.error({ message: response.data.errorMessage });
      }
      if (response.data.result.calendar)
        dispatch(
          setExtraEvents(
            extraEventsList(
              EXTRA_EVENTS_DATES,
              response.data.result.calendar,
              args.event_id
            )
          )
        );
      return response.data;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({ message: error.response.data.errorMessage });
      } else {
        notification.error({
          message: "Произошла ошибка получения дополнительных мероприятий",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
