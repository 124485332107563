import React, { useEffect, useState } from "react";
import { Form, Button, notification } from "antd";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import {
    getEventDataLength,
    getIsDataLoaded,
    getResultsEventDate,
    getResultsEventId,
    getResultsEventStatus,
    getResultsLoading,
    getResultsNavigate,
} from "../../store/results/selectors";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { AppRoute, EventStatus } from "../../const";
import {
    loadResultsAction,
    uploadResultsAction,
} from "../../store/results/thunk";
import {
    resetNavigateFlag,
    setResultsActive,
    setResultsEventDate,
    setResultsEventId,
} from "../../store/results/slice";

import styles from "./index.module.scss";
import EventName from "../../components/Common/EventName/EventName";
import EventDate from "../../components/Common/EventDate/EventDate";
import FileUpload from "../../components/ResultsFilesUploadScreen/FileUpload";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import CancelEventMessage from "../../components/Common/CancelEventMessage/CancelEventMessage";
import useLinks from "../../customHooks/useLinks";
import WebsiteLink from "../../components/Common/WebsiteLink/WebsiteLink";
import { protocolTooOldToChange } from "../../utils";
import { getIsUserAdmin } from "../../store/user/selectors";

const ResultsFilesUpload: React.FC = () => {
    const [files, setFiles] = useState<File[]>([]);
    const eventId = useAppSelector(getResultsEventId);
    const eventDate = useAppSelector(getResultsEventDate);
    const isEventData = useAppSelector(getEventDataLength);
    const eventStatus = useAppSelector(getResultsEventStatus);
    const loading = useAppSelector(getResultsLoading);
    const resultsNavigate = useAppSelector(getResultsNavigate);
    const isUserAdmin = useAppSelector(getIsUserAdmin);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isResultsLoaded = useAppSelector(getIsDataLoaded);
    const [link] = useLinks(eventId, eventDate);

    useEffect(() => {
        document.title = "NRMS - загрузка результатов";
    }, []);

    useEffect(() => {
        if (resultsNavigate) {
            dispatch(resetNavigateFlag());
            navigate(AppRoute.Results);
        }
    }, [resultsNavigate, dispatch, navigate]);

    useEffect(() => {
        if (eventDate && eventId && !isResultsLoaded) {
            dispatch(loadResultsAction({ event_id: eventId, date: eventDate }));
        }
    }, [eventId, eventDate, isResultsLoaded, dispatch]);

    const handleFilesChange = (filesArray: File[]) => {
        setFiles(filesArray);
    };

    const onFinish = (value: any) => {
        // e.preventDefault();
        const formData = new FormData();
        if (eventStatus === EventStatus.Pause) {
            notification.error({ message: `Мероприятие на паузе, загрузка результатов невозможна. 
            В случае вопросов обратитесь к вашему амбассадору поддержки или на eventsupport@5verst.ru` });
            return;
        }

        if (!eventId) {
            notification.warn({ message: "Укажите старт" });
            return;
        }
        if (!eventDate) {
            notification.warn({ message: "Укажите дату" });
            return;
        }

        const formFiles = [...files];
        if (formFiles.length < 2) {
            notification.error({ message: "Выберите минимум два файла" });
            return;
        }
        if (eventId && eventDate && formFiles) {
            for (let i = 0; i < formFiles.length; i++) {
                formData.append("files", formFiles[i]);
            }

            formData.append("id", eventId.toString());
            formData.append("date", eventDate);
            dispatch(uploadResultsAction(formData));
        }
    };

    if (loading) return <LoadingScreen />;

    return (
        <>
            <Form name="load-results" autoComplete="off" onFinish={onFinish}>
                <div className={styles.wrapper}>
                    {/* Поле забега */}
                    <Form.Item label="Старт" name="park">
                        <div className={styles.eventName}>
                            <EventName
                                eventId={eventId}
                                setEventId={setResultsEventId}
                            />
                        </div>
                    </Form.Item>

                    {/* Поле даты */}
                    <Form.Item label="Дата мероприятия" name="date">
                        <EventDate
                            eventDate={eventDate}
                            setEventDate={setResultsEventDate}
                        />
                    </Form.Item>

                    {isEventData ? (
                        <div className={styles.links}>
                            <div className={styles.resultLink}>
                                <span>Есть результаты на данную дату </span>
                                <Button
                                    size="small"
                                    type="primary"
                                    onClick={() => {
                                        dispatch(setResultsActive(true));
                                        navigate(AppRoute.Results);
                                    }}
                                >
                                    Редактировать
                                </Button>
                            </div>
                            <WebsiteLink
                                link={link}
                                text={"Посмотреть на сайте"}
                            />
                        </div>
                    ) : null}
                    {eventStatus === EventStatus.Cancel ? (
                        <>
                            <CancelEventMessage />
                            <WebsiteLink
                                link={link}
                                text={"Посмотреть результаты на сайте"}
                            />
                        </>
                    ) : (
                        <>
                            {/* Загрузка файлов секундомера и таймера */}
                            <FileUpload
                                files={files}
                                handleFiles={handleFilesChange}
                            />

                            {/* Кнопка отправки данных */}
                            <Form.Item>
                                <Button
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        loading ||
                                        (protocolTooOldToChange(eventDate) &&
                                            !isUserAdmin)
                                    }
                                >
                                    Отправить
                                </Button>{" "}
                            </Form.Item>
                        </>
                    )}
                </div>
            </Form>
        </>
    );
};

export default ResultsFilesUpload;
