import { TAthlete } from "./store/types";

export const TEST_MODE = process.env.REACT_APP_TEST_MODE || false;

export const { REACT_APP_WEBSITE_URL } = process.env;

export enum AppRoute {
    CancelEvent = "/cancel",
    EditVolunteers = "/editvolunteers",
    EditResults = "/editresults",
    ExtraEvents = "/extraevents",
    Home = "/",
    Incident = "/incident",
    Login = "/login",
    Main = "/main",
    Member = "/member",
    // Logout = "logout",
    Results = "/results",
    ResultsFilesUpload = "/resultsfiles",
    Volunteers = "/volunteers",
    RemindPassword = "/remindpassword",
    UpdatePassword = "/updatepassword",
    SetPassword = "/p/:hash",
}

export enum ExtLink {
    Results = "/results",
    Volunteers = "/volunteer",
}

export enum APIRoute {
    AllParks = "/website/event/list",
    // AuthParks = '/website/event/listByVerstId',
    AuthParks = "/event/listByVerstId",
    CancelEvent = "/event/cancel",
    CancelRestore = "/event/restore",
    EventStatus = "/event/status/get",
    ExtraEventsAdd = "/calendar/add",
    ExtraEventDelete = "/calendar/delete",
    ExtraEventUpdate = "/calendar/update",
    ExtraEventsGetCalendar = "/calendar/event/list",
    EmergencyDataLoad = "/athlete/contact/get",
    VolunteerRoles = "/volunteer/role/list",
    VolunteersLoad = "/event/volunteer/list",
    VolunteersSave = "/volunteer/event/save",
    VolunteersClear = "/volunteer/event/clear",
    AthleteByIdPart = "/athlete/getListByIdPart",
    AthleteByNamePart = "/athlete/getListByNamePart",
    Login = "/auth/login",
    Logout = "/auth/logout",
    ResultsUpload = "/results/upload",
    ResultsLoad = "/results/getByDateAndEventId",
    ResultsSave = "/results/save",
    RequestPasswordChange = "/account/password/change/init",
    SetNewPassword = "/account/password/change/submit",
    UpdateCurrentPassword = "/account/athlete/password/update",
    VolunteerSummary = "/athlete/volunteer/summary",
}

export const menuItems = [
    {
        title: "Главная",
        route: AppRoute.Home,
    },
    {
        title: "Результаты",
        route: AppRoute.Results,
    },
    {
        title: "Волонтёры",
        route: AppRoute.Volunteers,
    },
    {
        title: "Найти участника",
        route: AppRoute.Member,
    },
    {
        title: "Выход",
        route: AppRoute.Home,
    },
];

export enum AuthorizationStatus {
    Auth = "AUTH",
    NoAuth = "NO_AUTH",
    Unknown = "UNKNOWN",
}

export enum WarningMessage {
    AuthFail = "Не забудьте авторизоваться",
    WrongPassword = "Неверный логин/пароль",
    AccessDenied = "Доступ запрещен. Обратитесь в службу поддержки мероприятий: eventsupport@5verst.ru",
    SigninFail = "Ошибка авторизации",
    UploadFail = "Ошибка при загрузке файлов",
    UploadSuccess = "Данные загружены",
    ResultsLoadFail = "Ошибка загрузки результатов",
    SaveSuccess = "Данные сохранены",
    ResultsDoubleResults = "Невозможно сохранить протокол по причине дублирования результатов одного или нескольких участников",
    NetworkError = "Ошибка сети",
    RolesLoadFail = "Загрузка позиций волонтёров не удалась",
    ParksLoadFail = "Загрузка парков не удалась",
    AuthRequired = "Необходимо авторизоваться",
    VolunteersLoadFail = "Ошибка загрузки волонтеров",
    VolunteerLoadFail = "Загрузка участника не удалась",
    VolunteerSaveFail = "Ошибка сохранения волонтеров",
    VolunteersSaveWarning = "",
    WrongOldPassword = "Старый пароль введен неверно",
    PasswordRequestSuccess = "Вам отправлена ссылка для смены пароля ",
    PasswordSetSuccess = "Пароль успешно изменен",
    AthleteNotFound = "Участник с таким email не найден",
    BadIDRequest = "Ошибка при поиске ID",
    InternalSystemError = "Произошла внутренняя ошибка системы",
    VolunteerSummarySuccess = "Загружен волонтерский опыт",
}

export const SERVER_RESPONSE_OK = 200;

export enum ErrorCodes {
    AccessDenied = 10004,
    WrongLoginPassword = 10005,
    AthleteNotFound = 10006,
    NetworkError = "ERR_NETWORK",
    NoExtraEvent = 10009,
    ClearVolunteersOnSite = 10022,
    DoubleResults = 10023,
    BadIDRequest = -1,
    InternalSystemError = 99999,
    WrongOldPassword = 10020,
}

export const EMPTY_ATHLETE: TAthlete = {
    id: null,
    full_name: "",
    home_event: "",
};

export enum MessageType {
    default = "default",
    warning = "warning",
    error = "error",
    success = "success",
}

export enum MessageFontSize {
    default = "default",
    large = "large",
}

export const VOLUNTEERS_SAVE_MESSAGE = "Список волонтёров сохранён.";
export const VOLUNTEERS_SAVE_WARNING =
    'Список волонтёров сохранён.  Для подтверждения списка волонтеров и рассылки писем благодарности нажмите "Загрузить на сайт" ';
export const VOLUNTEERS_CLEAR_MESSAGE = "Список сохранён";
export const VOLUNTEERS_UPLOAD_MESSAGE = "Список волонтёров загружен на сайт.";

export const ID_UNKNOWN = 790000404;

export const CANCEL_EVENT_REASON_MAX_LENGTH = 140;

/** Максимальный размер загружаемого файла результатов в байтах */
export const MAX_FILE_SIZE = 200000;

export enum EventStatus {
    Test = 1,
    Open = 2,
    Cancel = 3,
    Pause = 5,
}

export enum VolunteersStatusId {
    Draft = 1,
    Final = 2,
}

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// EventDate && ExtraEvents
export const DATE_FORMAT = "DD.MM.YYYY";
export const EXTRA_EVENT_TIME_FORMAT = "HH:mm";
export const EXTRA_EVENTS_CALENDAR_YEAR = 2024;
export const EXTRA_EVENTS_DATES = ["01.01.2024", "12.06.2024", "03.11.2024"]; //Даты когда можно дубли - отдельно
export const JUNE_EXTRA_EVENT = "2024-06-12";
export const MINIMUM_EVENT_DATE = "2022-05-21";
export const EXTRA_EVENT_ALOWED_TIMES = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
];

// количество разрешенных результатов в один день в разных локациях обычно и в дни исключений
export const RESULTS_NUMBER_ALLOWED = 1;
export const RESULTS_NUMBER_ALLOWED_EXTRA = 2;
export const DOUBLE_RESULTS_ALLOWED_DATES = ["01.01.2023"];

export enum DatePickerColors {
    TextColor = "#ffffff",
    ExtraDateBackgroundColor = "#de9636",
    SaturdayBackgroundColor = "#6e84be",
    EventDayBackgroundColor = "#e6464c",
}

export const ALLOWED_DAYS_FOR_CHANGE_PROTOCOL = 15;
export const ADMIN_IDS = [790092939];

export const MIN_VALID_TIME = "00:13:00";

export const ONLY_ADULT_VOLUNTEER_ROLES = [
    "Организатор",
    "Маршал",
    "Замыкающий",
];
