import { Button, notification, Select } from "antd";
import React from "react";
import { EXTRA_EVENT_ALOWED_TIMES } from "../../../const";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getCalendar, getIsLoading } from "../../../store/extraEvents/selector";
import { setExtraEvent } from "../../../store/extraEvents/slice";
import {
  AddExtraEventAction,
  DeleteExtraEventAction,
  UpdateExtraEventAction,
} from "../../../store/extraEvents/thunk";
import { TExtraEvent } from "../../../store/extraEvents/types";
import { isEventActive, timeStringFromEvent } from "../../../utils/extraEvents";
import styles from "./index.module.scss";

interface IProps {
  event: TExtraEvent;
  index: number;
}

const ExtraEvent: React.FC<IProps> = ({ event, index }) => {
  const calendar = useAppSelector(getCalendar);
  const loading = useAppSelector(getIsLoading);
  const dispatch = useAppDispatch();
  const timeString = timeStringFromEvent(event);

  const toggleToAddEvent = () => {
    dispatch(
      setExtraEvent({ event: { ...event, isAdded: !event.isAdded }, index })
    );
  };

  const isEventInCalendar = (
    eventItem: TExtraEvent,
    eventsCalendar: TExtraEvent[]
  ) => {
    return (
      eventsCalendar.findIndex((e) => e.event_date === eventItem.event_date) >=
      0
    );
  };

  const isDisabled = !isEventActive(event);

  const handleSaveEvent = () => {
    if (!isEventInCalendar(event, calendar)) {
      if (event.isAdded) {
        dispatch(AddExtraEventAction(event));
      } else {
        notification.warn({ message: "Мероприятие не выбрано" });
      }
    } else if (!event.isAdded) {
      dispatch(DeleteExtraEventAction(event));
    } else {
      dispatch(UpdateExtraEventAction(event));
    }
  };

  const handleTimeChange = (time: string) => {
    let [hours, minutes] = time.split(":");
    dispatch(
      setExtraEvent({
        event: {
          ...event,
          hour: Number(hours),
          minute: Number(minutes),
        },
        index,
      })
    );
  };

  return (
    <div className={styles.inputs}>
      <input
        className={styles.checkbox}
        type="checkbox"
        name="yes"
        id={`extraEvent_${event.event_date}`}
        checked={event.isAdded}
        onChange={toggleToAddEvent}
        disabled={isDisabled}
      />
      <label htmlFor={`extraEvent_${event.event_date}`}>
        {event.event_date}
      </label>{" "}
      {/* в{" "}
      <Select
        placeholder="Выберите старт"
        value={timeString}
        onChange={handleTimeChange}
        options={EXTRA_EVENT_ALOWED_TIMES.map((time) => {
          return { value: time, label: time };
        })}
        disabled={isDisabled}
      /> */}
      {(event.event_date !== "12.06.2024" && event.event_date !== "03.11.2024") && 
      <>в 
        <Select
          placeholder="Выберите старт"
          value={timeString}
          onChange={handleTimeChange}
          options={EXTRA_EVENT_ALOWED_TIMES.map((time) => {
            return { value: time, label: time };
          })}
          disabled={isDisabled}
        />
      </>}
      {!isDisabled && (
        <Button
          className="submit-button"
          type="primary"
          disabled={isDisabled || loading || !event.event_id}
          onClick={handleSaveEvent}
        >
          Отправить
        </Button>
      )}
    </div>
  );
};

export default ExtraEvent;
